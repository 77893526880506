import axios from 'axios';
import { ME_URL } from '../constants';
import {IUserEditProps,
  //  UserStatusType
  } from '../interfaces/user';

export function getMe() {
  return axios.get(ME_URL);
}

export function editMe(data: IUserEditProps, companyId?: number) {
  if (companyId) {
    return axios.put(`${ME_URL}?country_code=${7}&company_id=${companyId}`, data);
  }
  return axios.put(`${ME_URL}?country_code=${7}`, data);
}
