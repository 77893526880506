import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { Reducer } from 'redux';
import { PersistPartial } from 'redux-persist/es/persistReducer';
import { TAppActions } from '../rootDuck';
import { ActionsUnion, createAction } from '../../utils/action-helper';
// import { IServerResponse } from '../../interfaces/server';
// import { IProductType, ITypeParameter, ICityType } from '../../interfaces/productType';
import { ICityType } from '../../interfaces/cities';
// import { any } from 'prop-types';

const FETCH_SUCCESS = 'cities/FETCH_SUCCESS';
const SELECT_CITY = 'cities/SELECT_CITY';

// const FETCH_BY_ID_SUCCESS = 'productType/FETCH_BY_ID_SUCCESS';

// const FETCH_PARAMETERS_SUCCESS = 'productType/FETCH_PARAMETERS_SUCCESS';

// const EDIT_SUCCESS = 'productType/EDIT_SUCCESS';

// const CLEAR_PARAMS = 'productType/CLEAR_PARAMS';

export interface IInitialState {
  // citiesList: any;
  // selectedCity: any;
  citiesList: ICityType[];
  selectedCity: any;
  // cities: any;

  //   page: number;
  //   per_page: number;
  //   total: number;
  // productTypes: IProductType[] | undefined;
  //   loading: boolean;
  //   success: boolean;

  //   productType: IProductType | undefined;
  //   byIdLoading: boolean;
  //   byIdSuccess: boolean;

  //   typeParams: ITypeParameter[] | undefined;
  //   paramsLoading: boolean;

  //   editLoading: boolean;
  //   editSuccess: boolean;
}

const initialState: IInitialState = {
  // cities: [],
  citiesList: [],
  selectedCity: null,
  //   page: 1,
  //   per_page: 20,
  //   total: 0,
  //   productTypes: undefined,
  //   loading: false,
  //   success: false,

  //   productType: undefined,
  //   byIdLoading: false,
  //   byIdSuccess: false,

  //   typeParams: undefined,
  //   paramsLoading: false,

  //   editLoading: false,
  //   editSuccess: false,
};

export const reducer: Reducer<IInitialState & PersistPartial, TAppActions> = persistReducer(
  { storage, key: 'productTypes', whitelist: ['user', 'authToken'] },
  (state = initialState, action) => {
    switch (action.type) {
      case FETCH_SUCCESS: {
        return {
          ...state,
          citiesList: action.payload,
          // per_page: action.payload.per_page,
          // total: action.payload.total,
          // productTypes: action.payload.data,
          // loading: false,
          // success: true,
        };
      }
      case SELECT_CITY: {
        return {
          ...state,
          // cities: action.payload,
          selectedCity: action.payload
          // per_page: action.payload.per_page,
          // total: action.payload.total,
          // productTypes: action.payload.data,
          // loading: false,
          // success: true,
        };
      }

      //   case FETCH_BY_ID_SUCCESS: {
      //     return {
      //       ...state,
      //       productType: action.payload.data,
      //       byIdLoading: false,
      //       byIdSuccess: true,
      //     };
      //   }

      //   case FETCH_PARAMETERS_SUCCESS: {
      //     return {
      //       ...state,
      //       paramsLoading: false,
      //       typeParams: action.payload,
      //     };
      //   }

      //   case EDIT_SUCCESS: {
      //     return { ...state, editLoading: false, editSuccess: true, productType: action.payload };
      //   }

      //   case CLEAR_PARAMS: {
      //     return {
      //       ...state,
      //       typeParams: undefined,
      //     };
      //   }

      default:
        return state;
    }
  }
);

export const actions = {
  // fetchSuccess: (payload: IServerResponse<IProductType[]>) => createAction(FETCH_SUCCESS, payload),
  fetchCitiesSuccess: (payload: any) => createAction(FETCH_SUCCESS, payload),
  selectCity: (payload: any) => createAction(SELECT_CITY, payload),

  //   fetchByIdSuccess: (payload: IServerResponse<IProductType>) =>
  //     createAction(FETCH_BY_ID_SUCCESS, payload),

  //   editSuccess: (payload: IProductType) => createAction(EDIT_SUCCESS, payload),

  //   fetchParamsByIdSuccess: (payload: ITypeParameter[]) =>
  //     createAction(FETCH_PARAMETERS_SUCCESS, payload),

  //   clearParams: () => createAction(CLEAR_PARAMS),
};

export type TActions = ActionsUnion<typeof actions>;
