import { useSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { loginUserBySmsCode } from '../../../crud/auth.crud';
import { getResponseMessage } from '../../../utils/utils';
import { actions as authActions } from '../../../store/ducks/auth.duck';
import { phoneFormat } from '../utils/phoneFormat';

export const useFetchCheckSms: any = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState<any>(null);
  const [success, setSuccess] = useState(false);
  const dispatch = useDispatch();
  const fetchCheckSms = useCallback(
    async (payload: { phone: string; code: string }) => {
      setLoading(true);
      loginUserBySmsCode({
        phoneNumbers: phoneFormat(payload.phone)!,
        code: payload.code,
        countryCode: 7,
      })
        .then((res) => {
          dispatch(authActions.loginSuccess(res.data));
          setSuccess(true);
        })
        .catch((e) => {
          enqueueSnackbar(`Ошибка: ${getResponseMessage(e)}`, { variant: 'error' });
        })
        .finally(() => {
          setLoading(false);
          setErr(null);
          setSuccess(false);
          if(err){}
        });
    },
    [dispatch]
  );

  return [fetchCheckSms, loading, success];
};
