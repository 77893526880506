import { useCallback, useState } from 'react';

declare global {
  interface Window {
    cp: any;
  }
}

export const useOpenPayWidget: () => [
  (userId: number, tariffId: number, price: number) => void,
  boolean
] = () => {
  const [success, setSuccess] = useState(false);

  const payOperation = useCallback((userId: number, tariffId: number, price: number) => {
    
    const widget = new window.cp.CloudPayments();
    var receipt = {
      userId: userId,
      tariffId: tariffId,
    };

    const options = {
      // publicId: import.meta.env.VITE_API_PK,
      publicId: "test_api_00000000000000000000001",
      description: 'Оплата тарифа',
      amount: price,
      currency: 'RUB',
      // accountId: 'user@example.com',
      // invoiceId: '1234567',
      skin: 'modern',
      data: {
        userId: userId,
        tariffId: tariffId,
        CloudPayments: {
          CustomerReceipt: receipt,
          recurrent: {
           interval: 'Month',
           period: 1, 
           customerReceipt: receipt
        }
        }
      },
    };

    widget.charge(options, function (options: any) { 
        console.log('Payment successful:', options);
      setSuccess(true);
    },
    function (reason: any, options: any) { 
        console.error('Payment failed:', reason, options);
    });
  }, []);
  return [payOperation, success];
};
