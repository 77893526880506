import React from 'react';
import { Button, ThemeProvider } from '@material-ui/core';
import { createTheme } from '@material-ui/core/styles'

interface IColorButton {
  color: string;
  darkColor?: string;
  onClick?: () => {} | void;
  title?: string;
}

const ColorButton: React.FC<IColorButton> = ({ children, color, onClick, title }) => {
  const theme = createTheme({
    palette: {
      primary: {
        main: color,
      },
    },
  });
  return (
    <ThemeProvider theme={theme}>
      <Button onClick={onClick} color="primary" variant="contained" disableElevation>
        {children}
      </Button>
    </ThemeProvider>
  );
};

export default ColorButton;
