import { all, fork } from 'redux-saga/effects';
import { combineReducers } from 'redux';
import { PersistPartial } from 'redux-persist/es/persistReducer';

import * as auth from './ducks/auth.duck';
import * as users from './ducks/users.duck';
import * as profile from './ducks/profile.duck';
import * as companies from './ducks/companies.duck';

import * as brands from './ducks/brands.duck';
import * as models from './ducks/models.duck';
import * as generations from './ducks/generations.duck';
import * as bodies from './ducks/bodies.duck';
import * as modifications from './ducks/modifications.duck';
import * as equipments from './ducks/equipments.duck';

import * as pcountries from './ducks/pcountries.duck';
import * as pbodies from './ducks/pbodies.duck';
import * as pgearboxes from './ducks/pgearboxes.duck';
import * as pdrives from './ducks/pdrives.duck';
import * as pengines from './ducks/pengines.duck';
import * as pfuels from './ducks/pfuels.duck';

import * as advantage from './ducks/advantage.duck';
import * as poptions from './ducks/poptions.duck';
import * as poptionsGroups from './ducks/poptionsGroups.duck';

import * as categories from './ducks/categories.duck';
import * as products from './ducks/product.duck';
import * as productTypes from './ducks/productType.duck';
import * as cities from './ducks/cities.duck';
import * as orders from './ducks/orders.duck';
import * as agreement from './ducks/agreement.duck';
import * as yaLocations from './ducks/yaLocations.duck';
import * as stores from './ducks/store.duck';
import * as tariffs from './ducks/tariffs.duck';

import { metronic } from '../../_metronic';

export type TAppActions =
  | auth.TActions
  | companies.TActions
  | users.TActions
  | profile.TActions
  | models.TActions
  | brands.TActions
  | generations.TActions
  | bodies.TActions
  | modifications.TActions
  | equipments.TActions
  | pcountries.TActions
  | pbodies.TActions
  | pgearboxes.TActions
  | pdrives.TActions
  | pengines.TActions
  | pfuels.TActions
  | advantage.TActions
  | poptions.TActions
  | poptionsGroups.TActions
  | categories.TActions
  | products.TActions
  | productTypes.TActions
  | cities.TActions
  | orders.TActions
  | agreement.TActions
  | yaLocations.TActions
  | stores.TActions
  | tariffs.TActions
  ;

export interface IAppState {
  i18n: typeof metronic.i18n.reducer;
  builder: typeof metronic.builder.initialState;
  auth: auth.IInitialState & PersistPartial;
  companies: companies.IInitialState & PersistPartial;
  users: users.IInitialState & PersistPartial;
  profile: profile.IInitialState & PersistPartial;
  brands: brands.IInitialState & PersistPartial;
  models: models.IInitialState & PersistPartial;
  generations: generations.IInitialState & PersistPartial;
  bodies: bodies.IInitialState & PersistPartial;
  modifications: modifications.IInitialState & PersistPartial;
  equipments: equipments.IInitialState & PersistPartial;
  pcountries: pcountries.IInitialState & PersistPartial;
  pbodies: pbodies.IInitialState & PersistPartial;
  pgearboxes: pgearboxes.IInitialState & PersistPartial;
  pdrives: pdrives.IInitialState & PersistPartial;
  pengines: pengines.IInitialState & PersistPartial;
  pfuels: pfuels.IInitialState & PersistPartial;
  advantage: advantage.IInitialState & PersistPartial;
  poptions: poptions.IInitialState & PersistPartial;
  poptionsGroups: poptionsGroups.IInitialState & PersistPartial;
  categories: categories.IInitialState & PersistPartial;
  products: products.IInitialState & PersistPartial;
  productTypes: productTypes.IInitialState & PersistPartial;
  cities: cities.IInitialState & PersistPartial;
  orders: orders.IInitialState & PersistPartial;
  agreement: agreement.IInitialState;
  yaLocations: yaLocations.IInitialState;
  stores: stores.IInitialState & PersistPartial;
  tariffs: tariffs.IInitialState & PersistPartial;
}

export const rootReducer: (state: IAppState, action: TAppActions) => IAppState = combineReducers<
  IAppState,
  TAppActions
>({
  auth: auth.reducer,
  companies: companies.reducer,
  users: users.reducer,
  profile: profile.reducer,
  i18n: metronic.i18n.reducer,
  builder: metronic.builder.reducer,
  brands: brands.reducer,
  models: models.reducer,
  generations: generations.reducer,
  bodies: bodies.reducer,
  modifications: modifications.reducer,
  equipments: equipments.reducer,
  pcountries: pcountries.reducer,
  pbodies: pbodies.reducer,
  pgearboxes: pgearboxes.reducer,
  pdrives: pdrives.reducer,
  pengines: pengines.reducer,
  pfuels: pfuels.reducer,
  advantage: advantage.reducer,
  poptions: poptions.reducer,
  poptionsGroups: poptionsGroups.reducer,
  categories: categories.reducer,
  products: products.reducer,
  productTypes: productTypes.reducer,
  cities: cities.reducer,
  orders: orders.reducer,
  agreement: agreement.reducer,
  yaLocations: yaLocations.reducer,
  stores: stores.reducer,
  tariffs: tariffs.reducer,
});

export function* rootSaga() {
  yield all(
    [
      users.saga,
      profile.saga,
      brands.saga,
      models.saga,
      generations.saga,
      bodies.saga,
      modifications.saga,
      equipments.saga,
      pcountries.saga,
      pbodies.saga,
      pgearboxes.saga,
      pdrives.saga,
      pengines.saga,
      pfuels.saga,
      advantage.saga,
      poptions.saga,
      poptionsGroups.saga,
      orders.saga,
      yaLocations.saga,
    ].map((saga) => fork(saga))
  );
}
