import axios from "axios"
import {URL} from '../../../constants/new';

export const client = {
    async get(url: string, token: string){
        const {data} = await axios.get(url,  {
            headers: {
                "X-AUTH-TOKEN": token
            }
        });
        if(data && data.data)
        {
            return data;
        }
        return 'error client'
    },
    async post(url: string, token: string, data: {
        tariff_id: number,
        days: number
    }){
        const response = await axios.post(URL + "/" + url, data, {
            headers: {
                "X-AUTH-TOKEN": token
            }, 
        });
        if(response && response.data)
        {
            return true;
        }
        return false
    }
}