import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { Reducer } from 'redux';
import { PersistPartial } from 'redux-persist/es/persistReducer';
import { TAppActions } from '../rootDuck';

import { ActionsUnion, createAction } from '../../utils/action-helper';
import { IServerResponse } from '../../interfaces/server';
import { ICompany } from '../../interfaces/companies';
import { IUser } from '../../interfaces/user';
import { IStore } from '../../interfaces/store';
import { IProduct } from '../../interfaces/product';

const CLEAR_FETCH = 'companies/CLEAR_FETCH';
const FETCH_SUCCESS = 'companies/FETCH_SUCCESS';
const CLEAR_COMPANIES = 'companies/CLEAR_COMPANIES';

const FETCH_BY_ID_SUCCESS = 'companies/FETCH_BY_ID_SUCCESS';

const CLEAR_SEARCH = 'companies/CLEAR_SEARCH';
const SEARCH_SUCCESS = 'companies/SEARCH_SUCCESS';

const CLEAR_EDIT = 'companies/CLEAR_EDIT';
const EDIT_SUCCESS = 'companies/EDIT_SUCCESS';

const CLEAR_PHOTO_EDIT = 'companies/CLEAR_PHOTO_EDIT';
const EDIT_PHOTO_SUCCESS = 'companies/EDIT_PHOTO_SUCCESS';

const CLEAR_MANAGERS = 'companies/CLEAR_MANAGERS';
const GET_MANAGERS_SUCCESS = 'companies/GET_MANAGERS_SUCCESS';

const CLEAR_STORES = 'companies/CLEAR_STORES';
const GET_STORES_SUCCESS = 'companies/GET_STORES_SUCCESS';
const CLEAR_COMPANY = 'companies/CLEAR_COMPANY';
const RESET_PAGINATION_MANAGERS = 'companies/RESET_PAGINATION_MANAGERS';
const RESET_PAGINATION_STORES = 'companies/RESET_PAGINATION_STORES';
const RESET_PAGINATION_DISCOUNTS = 'companies/RESET_PAGINATION_DISCOUNTS';

const CLEAR_PAGE = 'companies/CLEAR_PAGE';
const DEL_PHOTO_SUCCESS = 'companies/DEL_PHOTO_SUCCESS';
const SET_PHOTO_SUCCESS = 'companies/SET_PHOTO_SUCCESS';

const FETCH_SUCCESS_DISCOUNTS = 'companies/FETCH_SUCCESS_DISCOUNTS';

export interface IInitialState {
  page: number;
  per_page: number;
  total: number;
  companies: ICompany[];
  loading: boolean;
  success: boolean;
  error: string | null;

  company: ICompany | null;
  byIdLoading: boolean;
  byIdSuccess: boolean;

  searchLoading: boolean;
  searchSuccess: boolean;
  searchError: string | null;

  editLoading: boolean;
  editSuccess: boolean;
  editError: string | null;

  editPhotoLoading: boolean;
  editPhotoSuccess: boolean;
  editPhotoError: string | null;

  managersLoading: boolean;
  managersSuccess: boolean;
  managersError: string | null;
  managersPage: number;
  managersPerPage: number;
  managersTotal: number;
  managers: IUser[];

  storesLoading: boolean;
  storesSuccess: boolean;
  storesError: string | null;
  storesPage: number;
  storesPerPage: number;
  storesTotal: number;
  stores: IStore[];
  delPhotoSuccess: boolean;

  discountsLoading: boolean;
  discountsSuccess: boolean;
  discountsError: string | null;
  discountsPage: number;
  discountsPerPage: number;
  discountsTotal: number;
  discounts: IProduct[];
}

const defaultPaginatorProps = { page: 1, per_page: 20, total: 0 };

const initialState: IInitialState = {
  ...defaultPaginatorProps,
  companies: [],
  loading: false,
  success: false,
  error: null,

  company: null,
  byIdLoading: false,
  byIdSuccess: false,

  searchLoading: false,
  searchSuccess: false,
  searchError: null,

  editLoading: false,
  editSuccess: false,
  editError: null,

  editPhotoLoading: false,
  editPhotoSuccess: false,
  editPhotoError: null,

  managersLoading: false,
  managersSuccess: false,
  managersError: null,
  managersPage: 1,
  managersPerPage: 20,
  managersTotal: 0,
  managers: [],

  storesLoading: false,
  storesSuccess: false,
  storesError: null,
  storesPage: 1,
  storesPerPage: 20,
  storesTotal: 0,
  stores: [],
  delPhotoSuccess: false,

  discountsLoading: false,
  discountsSuccess: false,
  discountsError: null,
  discountsPage: 1,
  discountsPerPage: 20,
  discountsTotal: 0,
  discounts: [],
};

export const reducer: Reducer<IInitialState & PersistPartial, TAppActions> = persistReducer(
  { storage, key: 'companies', whitelist: ['companies', 'authToken'] },
  (state = initialState, action) => {
    switch (action.type) {
      case CLEAR_FETCH: {
        return { ...state, loading: false, error: null, success: false };
      }

      case CLEAR_COMPANIES: {
        return { ...state, companies: [] };
      }

      case CLEAR_PAGE: {
        return { ...state, ...defaultPaginatorProps };
      }

      case FETCH_SUCCESS: {
        return {
          ...state,
          page: action.payload.page,
          per_page: action.payload.per_page,
          total: action.payload.total,
          companies: action.payload.data,
          loading: false,
          success: true,
        };
      }

      case CLEAR_SEARCH: {
        return {
          ...state,
          companies: [],
          searchLoading: false,
          searchError: null,
          searchSuccess: false,
        };
      }

      case SEARCH_SUCCESS: {
        return {
          ...state,
          page: action.payload.page,
          per_page: action.payload.per_page,
          total: action.payload.total,
          companies: action.payload.data,
          searchLoading: false,
          searchSuccess: true,
        };
      }

      case FETCH_BY_ID_SUCCESS: {
        return { ...state, company: action.payload.data, byIdLoading: false, byIdSuccess: true };
      }

      case CLEAR_EDIT: {
        return {
          ...state,
          editLoading: false,
          editError: null,
          editSuccess: false,
          company: null,
        };
      }

      case EDIT_SUCCESS: {
        return {
          ...state,
          editLoading: false,
          editSuccess: true,
          company: action.payload,
          managersTotal: action.payload.managers.length,
        };
      }

      case CLEAR_PHOTO_EDIT: {
        return {
          ...state,
          editPhotoLoading: false,
          editPhotoError: null,
          editPhotoSuccess: false,
          company: null,
        };
      }

      case EDIT_PHOTO_SUCCESS: {
        return {
          ...state,
          editPhotoLoading: false,
          editPhotoSuccess: true,
          company: action.payload,
          managersTotal: action.payload.managers.length,
        };
      }

      case CLEAR_MANAGERS: {
        return {
          ...state,
          managersLoading: false,
          managersSuccess: false,
          managersError: null,
          managersPage: 1,
          managersPerPage: 20,
          managersTotal: 0,
          managers: [],
        };
      }

      case GET_MANAGERS_SUCCESS: {
        return {
          ...state,
          managersPage: action.payload.page,
          managersPerPage: action.payload.per_page,
          managersTotal: action.payload.total,
          managersLoading: false,
          managersSuccess: true,
          managers: action.payload.data,
        };
      }

      case CLEAR_STORES: {
        return {
          ...state,
          storesLoading: false,
          storesSuccess: false,
          storesError: null,
          storesPage: 1,
          storesPerPage: 20,
          storesTotal: 0,
          stores: [],
        };
      }

      case GET_STORES_SUCCESS: {
        return {
          ...state,
          storesPage: action.payload.page,
          storesPerPage: action.payload.per_page,
          storesTotal: action.payload.total,
          storesLoading: false,
          storesSuccess: true,
          stores: action.payload.data,
        };
      }

      case CLEAR_COMPANY: {
        return {
          ...state,
          company: null,
        };
      }

      case RESET_PAGINATION_MANAGERS: {
        return {
          ...state,
          managersPage: 1,
          managersPerPage: 20,
          managersTotal: 0,
        };
      }

      case RESET_PAGINATION_STORES: {
        return {
          ...state,
          storesPage: 1,
          storesPerPage: 20,
          storesTotal: 0,
        };
      }

      case RESET_PAGINATION_DISCOUNTS: {
        return {
          ...state,
          discountsPage: 1,
          discountsPerPage: 20,
          discountsTotal: 0,
        };
      }

      case SET_PHOTO_SUCCESS: {
        return {
          ...state,
          product: action.payload.data,
          setMainPhotoLoading: false,
          setMainPhotoSuccess: true,
        };
      }

      case DEL_PHOTO_SUCCESS: {
        return {
          ...state,
          category: action.payload.data,
          delPhotoLoading: false,
          delPhotoSuccess: true,
        };
      }

      case FETCH_SUCCESS_DISCOUNTS: {
        console.log(action, 'actinoin');
        return {
          ...state,
          discountsPage: action.payload.paginator.current_page,
          discountsPerPage: action.payload.paginator.per_page,
          discountsTotal: action.payload.paginator.total,
          discountsLoading: false,
          discountsSuccess: true,
          discounts: action.payload.data,
        };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  clearFetch: () => createAction(CLEAR_FETCH),
  clearPage: () => createAction(CLEAR_PAGE),
  fetchSuccess: (payload: IServerResponse<ICompany[]>) => createAction(FETCH_SUCCESS, payload),
  clearCompanies: () => createAction(CLEAR_COMPANIES),

  fetchByIdSuccess: (payload: IServerResponse<ICompany>) =>
    createAction(FETCH_BY_ID_SUCCESS, payload),

  clearSearch: () => createAction(CLEAR_SEARCH),
  searchSuccess: (payload: IServerResponse<ICompany[]>) => createAction(SEARCH_SUCCESS, payload),

  clearEdit: () => createAction(CLEAR_EDIT),
  editSuccess: (data: ICompany) => createAction(EDIT_SUCCESS, data),

  clearEditPhoto: () => createAction(CLEAR_PHOTO_EDIT),
  editPhotoSuccess: (data: ICompany) => {
    return createAction(EDIT_PHOTO_SUCCESS, data);
  },

  clearManagers: () => createAction(CLEAR_MANAGERS),
  getManagersSuccess: (payload: IServerResponse<IUser[]>) =>
    createAction(GET_MANAGERS_SUCCESS, payload),

  clearStores: () => createAction(CLEAR_STORES),
  getStoresSuccess: (payload: IServerResponse<IStore[]>) =>
    createAction(GET_STORES_SUCCESS, payload),
  clearCompany: () => createAction(CLEAR_COMPANY),
  resetPaginationManagers: () => createAction(RESET_PAGINATION_MANAGERS),
  resetPaginationStores: () => createAction(RESET_PAGINATION_STORES),
  resetPaginationDiscounts: () => createAction(RESET_PAGINATION_DISCOUNTS),
  setMainPhotoSuccess: (payload: IServerResponse<ICompany>) =>
    createAction(SET_PHOTO_SUCCESS, payload),
  delPhotoSuccess: (payload: IServerResponse<ICompany>) => createAction(DEL_PHOTO_SUCCESS, payload),
  fetchSuccessDiscounts: (payload: IServerResponse<IProduct[]>) => {
    console.log('???', payload.data);
    return createAction(FETCH_SUCCESS_DISCOUNTS, payload);
  },
};

export type TActions = ActionsUnion<typeof actions>;
