import { useSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getResponseMessage } from '../../../utils/utils';
import { actions as agreementActions } from '../../../store/ducks/agreement.duck';
import { getPublicDoc } from '../../../crud/docs.crud';

export const useFetchAgreement: any = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState<any>(null);
  const [success, setSuccess] = useState(false);
  const dispatch = useDispatch();
  const fetchCheckSms = useCallback(async () => {
    setLoading(true);
    getPublicDoc('user_agreement')
      .then((res) => {
        dispatch(agreementActions.fetchSuccess(res.data));
        setSuccess(true);
      })
      .catch((e) => {
        enqueueSnackbar(`Ошибка: ${getResponseMessage(e)}`, { variant: 'error' });
      })
      .finally(() => {
        setLoading(false);
        setErr(null);
        setSuccess(false);
        if(err){}
      });
  }, [dispatch]);

  return [fetchCheckSms, loading, success];
};
