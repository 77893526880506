import { useSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { newPassword } from '../../../crud/auth.crud';
import { getResponseMessage } from '../../../utils/utils';

export const useFetchNewPass: any = () => {
  const { enqueueSnackbar } = useSnackbar();
  const intl = useIntl();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState<any>(null);
  const [success, setSuccess] = useState(false);
  const dispatch = useDispatch();
  const fetchNewPass = useCallback(
    async (payload: { password: string; password2: string; code: string }) => {
      setLoading(true);
      newPassword(payload)
        .then(() => {
          setSuccess(true);
          enqueueSnackbar(`${intl.formatMessage({ id: 'AUTH.PASSWORD.SUCCESS' })}`, {
            variant: 'success',
          });
          history.push('/auth');
        })
        .catch((e) => {
          enqueueSnackbar(`Ошибка: ${getResponseMessage(e)}`, { variant: 'error' });
        })
        .finally(() => {
          setLoading(false);
          setErr(null);
          setSuccess(false);
          if(err){}
        });
    },
    [dispatch]
  );

  return [fetchNewPass, loading, success];
};
