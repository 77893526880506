import React from 'react';
import './index.css'

import { useCallback, useEffect, useReducer } from 'react'
import { useOpenPayWidget } from './hooks/CloudPayment';
import userImage from './assets/image.png';
import premium from './assets/premium.png';
import { client } from './api/client';

declare global {
    interface Window {
      cp: any;
    }
}
  

const Payment: React.FC<any> = () => {
    const [openPayWidget, success] = useOpenPayWidget();
    const searchParams = new URLSearchParams(window.location.search);

    const initialState = {
        tariff: null,
        period: null,
        user: null,
        tarif_id: Number(searchParams.get('tarif_id')),
        period_id: String(searchParams.get('period_id')),
        token: String(searchParams.get('token')),
        successPayed: false
      };

    const [state, setState] = useReducer(
        (state: any, setState: any) => ({
          ...state,
          ...setState,
        }),
        initialState);

    useEffect(() => {
        if(state.token !== "null")
        {
            getMe();
            getTariffs();
        }
    },[state.token]);


    const getMe = async () => {
        const {data} = await client.get("https://lk-api.manit.app/api/me", state.token);
        setState({user: data});
    }

    const getTariffs = async () => {
        const {data} = await client.get("https://lk-api.manit.app/api/tariffs?page=1&per_page=20", state.token);
        if(data)
        {
            const tariff = data.find((i: any) => i.id === state.tarif_id)
            const selectPeriod = tariff.tariff_period_prices.find((p: any) => Number(p.id) === Number(state.period_id));
            setState({period: selectPeriod, tariff: tariff});
        }
    }

    const handlePay = useCallback(() => {
        if(state.user)
        {
            openPayWidget(state.user.id, state.tariff.id, state.period.price || 0);
        }
    }, [state.user]);


      useEffect(() => {
        if(success)
        {
            paymentSuccess();
        }
      }, [success]);

      const paymentSuccess = async () => {
        const data = {
            tariff_id: state.tariff.id,
            days: state.period.period.days
        };
        const response = await client.post("payment/cloud_payment", state.token, data);
        if(response)
        {
            setState({...initialState});
            window.location.replace(window.location.origin);
        }
      }

  return (
    <>
    {
        state.user && <div className="container">
        <div className="card">
            <div className="banner">
                <div className='banner-bg'></div> 
                <div className="avatar-container">
                    <img className="avatar" src={(state.user && state.user.avatar !== '') ? 'https://lk-api.manit.app/' + state.user.avatar : userImage} alt="user.avatar" />
                </div>
            </div> 
            <div className="user-info">
                <h4>{state.user && state.user.fio}</h4>
                <p>{state.user && state.user.email}</p>
            </div> 
            <div className="tariff-info">
                <div className='tariff-details'>
                    <p className='tariff-title'>Выбранный тариф</p>
                    <p className='tariff-name'> 
                        {
                            (state.tariff && state.tariff.id === 2)
                            && <img src={premium} alt="premium" className="tariff-icon" />
                        }
                        <span> {`«${state.tariff && state.tariff.name}»`}</span>
                    </p>
                </div>
                <div className='tariff-item'>
                    <p>Доступ к скидкам {state.period && state.period.period.days} дней </p>
                    <p className='tariff-icon'>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width={"30px"} >
                            <path fill="#32BEA6" d="M504.1,256C504.1,119,393,7.9,256,7.9C119,7.9,7.9,119,7.9,256C7.9,393,119,504.1,256,504.1C393,504.1,504.1,393,504.1,256z"></path>
                            <path fill="#FFF" d="M392.6,172.9c-5.8-15.1-17.7-12.7-30.6-10.1c-7.7,1.6-42,11.6-96.1,68.8c-22.5,23.7-37.3,42.6-47.1,57c-6-7.3-12.8-15.2-20-22.3C176.7,244.2,152,229,151,228.4c-10.3-6.3-23.8-3.1-30.2,7.3c-6.3,10.3-3.1,23.8,7.2,30.2c0.2,0.1,21.4,13.2,39.6,31.5c18.6,18.6,35.5,43.8,35.7,44.1c4.1,6.2,11,9.8,18.3,9.8c1.2,0,2.5-0.1,3.8-0.3c8.6-1.5,15.4-7.9,17.5-16.3c0.1-0.2,8.8-24.3,54.7-72.7c37-39.1,61.7-51.5,70.3-54.9c0.1,0,0.1,0,0.3,0c0,0,0.3-0.1,0.8-0.4c1.5-0.6,2.3-0.8,2.3-0.8c-0.4,0.1-0.6,0.1-0.6,0.1l0-0.1c4-1.7,11.4-4.9,11.5-5C393.3,196.1,397,184.1,392.6,172.9z"></path>
                        </svg>
                    </p>
                </div>
                <div className='tariff-item'>
                    <p>Оплата по QR-коду </p>
                    <p className='tariff-icon'>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width={"30px"} >
                            <path fill="#32BEA6" d="M504.1,256C504.1,119,393,7.9,256,7.9C119,7.9,7.9,119,7.9,256C7.9,393,119,504.1,256,504.1C393,504.1,504.1,393,504.1,256z"></path>
                            <path fill="#FFF" d="M392.6,172.9c-5.8-15.1-17.7-12.7-30.6-10.1c-7.7,1.6-42,11.6-96.1,68.8c-22.5,23.7-37.3,42.6-47.1,57c-6-7.3-12.8-15.2-20-22.3C176.7,244.2,152,229,151,228.4c-10.3-6.3-23.8-3.1-30.2,7.3c-6.3,10.3-3.1,23.8,7.2,30.2c0.2,0.1,21.4,13.2,39.6,31.5c18.6,18.6,35.5,43.8,35.7,44.1c4.1,6.2,11,9.8,18.3,9.8c1.2,0,2.5-0.1,3.8-0.3c8.6-1.5,15.4-7.9,17.5-16.3c0.1-0.2,8.8-24.3,54.7-72.7c37-39.1,61.7-51.5,70.3-54.9c0.1,0,0.1,0,0.3,0c0,0,0.3-0.1,0.8-0.4c1.5-0.6,2.3-0.8,2.3-0.8c-0.4,0.1-0.6,0.1-0.6,0.1l0-0.1c4-1.7,11.4-4.9,11.5-5C393.3,196.1,397,184.1,392.6,172.9z"></path>
                        </svg>
                    </p>
                </div>
                <div className='tariff-item'>
                    <p>Цена </p> 
                    <p>{state.period && state.period.price}.00 ₽</p>
                </div>
                <button onClick={handlePay} className='pay-button'>Оплатить</button>
            </div>
        </div>  
        <div className="alert custom-alert" role="alert">Не забудьте нажать "Вернуться в магазин" после оплаты</div>
    </div>
    }

    {
        state.token === "null" && 
        <div className="bg-gray-100 h-screen" style={{padding: "40px 20px", width: "100%", height: "100vh", display: "flex", justifyContent: "center", alignItems: "center"}} >
            <div className="bg-white p-4 md:mx-auto rounded-lg" style={{padding: "40px 20px", margin: "0 auto", display: "inline-block", justifyContent: "center"}}>
                <svg viewBox="0 0 24 24" className="text-green-600 w-16 h-16 mx-auto my-6 "  width="70px" height="70px" style={{color: "rgb(22 163 74)", display: "flex", justifyContent: "center"}}>
                    <path fill="currentColor"
                        d="M12,0A12,12,0,1,0,24,12,12.014,12.014,0,0,0,12,0Zm6.927,8.2-6.845,9.289a1.011,1.011,0,0,1-1.43.188L5.764,13.769a1,1,0,1,1,1.25-1.562l4.076,3.261,6.227-8.451A1,1,0,1,1,18.927,8.2Z">
                    </path>
                </svg>
                <div className="text-center mt-6">
                    <h3 className="md:text-2xl text-base text-gray-900 font-semibold text-center">Оплата завершена!</h3>
                    <p style={{fontWeight: "600"}} className="text-gray-600 my-2 mt-4">Благодарим вас за совершение вашего безопасного онлайн-платежа.</p>
                    <br />
                    <p style={{fontWeight: "600"}}> Хорошего дня!  </p>
                </div>
                <a href='manit://' style={{display: "flex", justifyContent: "center"}}>
                    <button className='w-full text-sm mt-6 py-3' style={{fontWeight: "600" , background: "#2388FF", color: "#fff", letterSpacing: "1px", borderRadius: "10px", border: "none", padding: "5px 20px" }}>
                        Вернуться в приложение
                    </button>
                </a>
            </div>
        </div>
    }

    </>
  )

}

export default Payment;