import { menuConfigItems } from '../constants/menuConfigItems';
import { TRole } from '../pages/auth/interfaces';

const getMenuConfig = (userRoles: TRole[] | undefined) => {
  if (userRoles === undefined) {
    return getDefaultMenu();
  } else if (userRoles[0] === 'ROLE_ADMIN') {
    return getAdminMenu();
  } else if (userRoles[0] === 'ROLE_MANAGER') {
    return getDefaultMenu();
  } else if (userRoles[0] === 'ROLE_VENDOR') {
    return getVendorMenu();
  } else if (userRoles[0] === 'ROLE_BUYER') {
    return getDefaultMenu();
  }
};

const {
  profile,
  products,
  // orders,
  users,
  legal,
  settings,
  logout,
} = menuConfigItems;

const getDefaultMenu = () => ({
  header: {
    self: {},
    items: [],
  },
  aside: {
    self: {},
    items: [profile, logout],
  },
});

const getAdminMenu = () => ({
  header: {
    self: {},
    items: [],
  },
  aside: {
    self: {},
    items: [
      {
        title: 'MENU.MAIN.PAGE',
        translate: 'MENU.MAIN.PAGE',
        bullet: 'dot',
        root: true,
        submenu: [
          {
            title: "Главная страница",
            page: 'index/page',
            translate: 'MENU.MAIN.PAGE',
            activePages: ['index/banner', 'index/categories', 'index/products'],
          },
          // {
          //   title: "Все скидки",
          //   page: 'discounts/page',
          //   translate: 'MENU.MAIN.DISCOUNTS',
          //   activePages: ['discounts/create'],
          // },
          // {
          //   title: "Все компании",
          //   page: 'company/page',
          //   translate: 'MENU.MAIN.COMPANIES',
          //   activePages: ['company/create'],
          // },
        ],
      },
      products,
      // orders,
      {
        root: true,
        title: 'MENU.COMPANIES',
        translate: 'MENU.COMPANIES',
        bullet: 'dot',
        submenu: [
          {
            title: "Все компании",
            page: 'company/page',
            translate: 'MENU.MAIN.COMPANIES',
            activePages: ['company/create'],
          },
          // {
          //   translate: 'SUBMENU.COMPANIES',
          //   title: 'SUBMENU.COMPANIES',
          //   page: 'companies/list',
          //   activePages: ['companies/new', 'companies/edit'],
          // },
          // {
          // translate: 'MENU.CATALOG.STORES',
          // title: 'MENU.CATALOG.STORES',
          // page: 'stores/list',
          // }
        ],
      },
      users,
      settings,
      legal,
      logout,
    ],
  },
});

const getVendorMenu = () => ({
  header: {
    self: {},
    items: [],
  },
  aside: {
    self: {},
    items: [
      // {
      //   title: 'MENU.MAIN.PAGE',
      //   translate: 'MENU.MAIN.PAGE',
      //   bullet: 'dot',
      //   root: true,
      //   submenu: [
      //     {
      //       title: "Главная страница",
      //       page: 'index/page',
      //       translate: 'Главная страница',
      //       activePages: ['index/banner', 'index/categories', 'index/products'],
      //     },
      //     {
      //       title: "Все скидки",
      //       page: 'discounts/page',
      //       translate: 'Все скидки',
      //       activePages: ['discounts/page', 'discounts/create', 'discounts/create'],
      //     },
      //     // {
      //     //   title: 'Дерево категорий',
      //     //   page: 'categories/tree',
      //     //   translate: 'MENU.CATALOG.CATEGORIES.TREE',
      //     // },
        
      //   ],
      // },
      {
        title: 'MENU.PRODUCTS',
        translate: 'MENU.PRODUCTS',
        bullet: 'dot',
        root: true,
        submenu: [
          {
            title: 'Список категорий',
            page: 'categories/list',
            translate: 'MENU.CATALOG.CATEGORIES.LIST',
            activePages: ['categories/new', 'categories/edit'],
          },
          {
            title: 'Дерево категорий',
            page: 'categories/tree',
            translate: 'MENU.CATALOG.CATEGORIES.TREE',
          },
          {
            title: 'Скидки компании',
            page: 'products/list',
            translate: 'CATEGORIES.TABLE.TOOLTIP.PRODUCTS',
            activePages: [
              'products/create',
              // 'products/types/edit',
              'products/edit',
              // 'products/types/create',
            ],
          },
        ],
      },
      // orders,
      {
        root: true,
        title: 'MENU.COMPANIES',
        translate: 'MENU.COMPANIES',
        bullet: 'dot',
        submenu: [
          {
            translate: 'SUBMENU.COMPANY_SETTINGS',
            title: 'SUBMENU.COMPANY_SETTINGS',
            page: 'companies/list',
            activePages: ['companies/new', 'companies/edit', 'companies/settings'],
          },
          {
            translate: 'MENU.CATALOG.STORES',
            title: 'MENU.CATALOG.STORES',
            page: 'stores/list',
            activePages: ['stores/create', 'stores/edit'],
          },
        ],
      },
      {
        root: true,
        title: 'MENU.SETTINGS',
        bullet: 'dot',
        translate: 'MENU.SETTINGS',
        submenu: [
          {
            title: 'SUBMENU.PROFILE',
            translate: 'SUBMENU.PROFILE',
            page: 'settings/profile',
          },
        ],
      },
      logout,
    ],
  },
});

export default getMenuConfig;
